import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const secretKey = 'QOKWRVOQ204544QWPR';

@Injectable({
  providedIn: 'root',
})
export class CryptoSecurityService {
  constructor() {}

  encryptData(value: string): string {
    return CryptoJS.AES.encrypt(value, secretKey).toString();
  }

  // setEncryptedItem(key: string, data: any) {
  //   const encryptedData = this.encryptData(data);
  //   sessionStorage.setItem(key, encryptedData);
  // }

  decryptData(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, secretKey).toString(CryptoJS.enc.Utf8);
  }

  // getDecryptedItem(key: string): any {
  //   const encryptedData = sessionStorage.getItem(key);
  //   if (encryptedData) {
  //     return this.decryptData(encryptedData);
  //   }
  //   return null;
  // }
}
