import { Injectable, inject } from '@angular/core';
import { CryptoSecurityService } from './crypto-security.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private cryptoSvc = inject(CryptoSecurityService);

  constructor() {}

  setItem(key: string, valueData: string | number | boolean | object): void {
    if (typeof valueData === 'string') {
      const encrypted = this.cryptoSvc.encryptData(valueData);
      sessionStorage.setItem(key, encrypted);
    }
    if (typeof valueData === 'number') {
      const value = valueData.toString();
      const encrypted = this.cryptoSvc.encryptData(value);
      sessionStorage.setItem(key, encrypted);
    }
    if (typeof valueData === 'boolean') {
      const value = valueData.toString();
      const encrypted = this.cryptoSvc.encryptData(value);
      sessionStorage.setItem(key, encrypted);
    }
    if (typeof valueData === 'object') {
      const value = JSON.stringify(valueData);
      const encrypted = this.cryptoSvc.encryptData(value);
      sessionStorage.setItem(key, encrypted);
    }
  }

  getItemObj<TipoData>(key: string): TipoData | null {
    const valueEncrypted = sessionStorage.getItem(key);
    if (!valueEncrypted) {
      return null;
    }
    const valueDecrypted = this.cryptoSvc.decryptData(valueEncrypted)
    return JSON.parse(valueDecrypted);
  }

  getItemStr(key: string): string | null {
    const valueEncrypted = sessionStorage.getItem(key);
    if (!valueEncrypted) {
      return null;
    }
    const valueDecrypted = this.cryptoSvc.decryptData(valueEncrypted)
    return valueDecrypted;
  }

  clearItems(): void {
    sessionStorage.clear();
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
