import { Injectable, Signal, WritableSignal, signal, inject } from '@angular/core';
import { DataSession } from '@core/enums/session/data.enum';
import { DataCarrito } from '@core/models/carrito/carrito.model';
import { SessionStorageService } from '@core/services/common/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class EstadoDatosService {
  private sessionStorageSvc = inject(SessionStorageService);
  private productosEnCarrito = this.sessionStorageSvc.getItemObj<DataCarrito[]>(DataSession.CARRITO);
  private shoppingCart = signal<boolean>(false);
  private idRol = signal<string>('1');
  private cartProducts = signal<number>(0);
  
  constructor() {
    if (this.productosEnCarrito) {
      this.cartProducts.set(this.productosEnCarrito.length);
    }
  }

  get comprando(): Signal<boolean> {
    return this.shoppingCart;
  }

  isShoppingCart(value: boolean) {
    this.shoppingCart.set(value);
  }

  get rolSeleccionado(): Signal<string> {
    return this.idRol;
  }

  selectRol(value: string) {
    this.idRol.set(value);
  }

  get canjeProductos(): Signal<number> {
    return this.cartProducts;
  }

  enviarCantidadProducto(value: number) {
    this.cartProducts.set(value);
  }
}
